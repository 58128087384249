import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import CalculadoraPVService from "../services/CalculadoraPVService"
import { fileValidate } from "../utils/Utils"
import DatosContacto from "./DatosContacto"

const FormularioAvanzado = ({ setLoading, setMuestraResultados, setDataResultados, toast, recaptchaRef }) => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [marcasModulos, setMarcasModulos] = useState([])
    const [marcasInversores, setMarcasInversores] = useState([])
    const [modulos, setModulos] = useState([])
    const [inversores, setInversores] = useState([])
    const [moduloSeleccionado, setModuloSeleccionado] = useState({})
    const [inversorSeleccionado, setInversorSeleccionado] = useState({})
    const service = new CalculadoraPVService()
    
    useEffect(() => {
        const service = new CalculadoraPVService()
        service.getMarcasModulos().then(data => setMarcasModulos(data))
        service.getMarcasInversores().then(data => setMarcasInversores(data))
    }, []) // eslint-disable-next-line
    
    // 
    const [muestraAnguloInvierno, setMuestraAnguloInvierno] = useState(false)
    const [muestraInclinacionMaxHSAT, setMuestraInclinacionMaxHSAT] = useState(false)
    const [muestraDatosModulo, setMuestraDatosModulo] = useState(false)
    const [muestraDatosInversor, setMuestraDatosInversor] = useState(false)
    const [esArregloHorizontal, setEsArregloHorizontal] = useState(false)

    const [muestraDatosContacto, setMuestraDatosContacto] = useState(false)
 
    const showDatosContacto = () => {
        setMuestraDatosContacto(!muestraDatosContacto)
    }

    const onSubmit = async (data) => {
        setLoading(true)

        // recaptcha token
        await recaptchaRef.current.executeAsync().then((captchaToken) => { 
            // servicio 
            service.doCalculosAvanzado(data, moduloSeleccionado, inversorSeleccionado, captchaToken).then(data => {
                if (data.status === "ok") {
                    setMuestraResultados(true)
                    setDataResultados(data)
                    setLoading(false)
                } else {
                    // error en el servicio
                    toast.current.show({severity:'error', summary: 'Error', detail:'Error en el servicio', life: 3000})
                    setLoading(false)
                }
            })
            .catch(e => {
                console.log(e)
                toast.current.show({severity:'error', summary: 'Error', detail:'Error en el servicio', life: 3000})
                setLoading(false)
            })
        })
    }

    const onMarcaModuloChange = (event) => {
        setLoading(true)
        setModulos([])
        setMuestraDatosModulo(false)
        if (event.target.value !== "") {
            const marca = event.target.value
            service.getModulos().then(data => setModulos(data.filter(m => m.marca === marca)))
        }
        setLoading(false)
    }

    const onModuloChange = (event) => {
        setLoading(true)
        setModuloSeleccionado({})
        if (event.target.value !== "") {
            const id = event.target.value
            let seleccionado = modulos.filter(m => m.id === id)
            if (seleccionado.length > 0) {
                seleccionado = seleccionado[0]
                setModuloSeleccionado(seleccionado)
                setMuestraDatosModulo(true)
            }
        } else {
            setMuestraDatosModulo(false)
        }
        setLoading(false)
    }

    const onMarcaInversorChange = (event) => {
        setLoading(true)
        setInversores([])
        setMuestraDatosInversor(false)
        if (event.target.value !== "") {
            const marca = event.target.value
            service.getInversores().then(data => setInversores(data.filter(i => i.marca === marca)))
        }
        setLoading(false)
    }

    const onInversorChange = (event) => {
        setLoading(true)
        if (event.target.value !== "") {
            const id = event.target.value
            const seleccionado = inversores.filter(i => i.id === id)
            if (seleccionado.length > 0) {
                setInversorSeleccionado(seleccionado[0])
                //console.log(seleccionado[0])
                setMuestraDatosInversor(true)
            }
        } else {
            setMuestraDatosInversor(false)
        }
        setLoading(false)
    }

    const onTipoArregloChange = (event) => {
        if (event.target.value !== "") {
            const tipoArreglo = event.target.value

            if (tipoArreglo === "tilt_2elev") {
                setMuestraAnguloInvierno(true) 
            } else {
                setMuestraAnguloInvierno(false)
            }

            if (tipoArreglo === "hori") {
                setEsArregloHorizontal(true)
            } else {
                setEsArregloHorizontal(false)
            }

            if (tipoArreglo === "hsat") {
                setMuestraInclinacionMaxHSAT(true)
            } else {
                setMuestraInclinacionMaxHSAT(false)
            }
        }
    }

    return (
        <>
            <br />
            <small>* Los datos de generación solar para el modelo avanzado son obtenidos a través de la API de Energías Renovables del Ministerio de Energía (<a href="https://api.minenergia.cl/" target="_blank" rel="noreferrer">https://api.minenergia.cl</a>).
            La información de módulos e inversores fue obtenida del listado oficial de equipamientos autorizados TE4 de la Superintendencia de Electricidad y Combustibles (SEC) actualizada al 03-11-2022 (<a href="https://www.sec.cl/gda/equipamiento-autorizado/" rel="noreferrer" target="_blank">https://www.sec.cl/gda/equipamiento-autorizado/</a>).
            </small>
            <br />
            <br />
            <small>** La información técnica que provee la SEC de módulos e inversores se encuentra incompleta, por lo que si no se encuentran algunos datos, la plataforma te pedirá ingresarlos.</small>
            <br />
            <br />

            <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Boleta de servicio:</label>
                            <input type="file" {...register("boleta", { required: true, validate: fileValidate })} />
                            {errors.boleta?.type === 'required' && <p className="text-danger">Debe seleccionar la boleta.</p>}
                            {errors.boleta?.type === 'validate' && <p className="text-danger">La boleta debe ser en formato PDF y no debe superar 1MB.</p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Marca módulo:</label>
                            <select className="form-control" {...register("marcaModulo", { required: true })} onChange={onMarcaModuloChange}>
                                <option value="">-- Seleccione Marca --</option>
                                {marcasModulos.map((m, i) => {
                                    return <option key={i} value={m}>{m}</option>
                                })}
                            </select>
                            {errors.marcaModulo?.type === 'required' && <p className="text-danger">Debe seleccionar la marca del módulo.</p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Modelo módulo:</label>
                            <select className="form-control" {...register("modulo", { required: true })} onChange={onModuloChange}>
                                <option value="">-- Seleccione Módulo --</option>
                                {modulos.map((m, i) => {
                                    return <option key={i} value={m.id}>{m.modelo}</option>
                                })}
                            </select>
                            {errors.modulo?.type === 'required' && <p className="text-danger">Debe seleccionar el módulo.</p>}
                        </div>
                    </div>
                    {(muestraDatosModulo === true && moduloSeleccionado !== {}) && <>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6 col-xs-6">
                                    <div className="form-group required">
                                        <label>Potencia (kW):</label>
                                        <input type="text" className="form-control" readOnly={true} value={(moduloSeleccionado.potenciaMax === null)? "": moduloSeleccionado.potenciaMax} />
                                    </div>
                                </div>  
                                <div className="col-md-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Vmp (V):</label>
                                        <input type="text" className="form-control" readOnly={true} value={(moduloSeleccionado.vpm === null)? "": moduloSeleccionado.vpm} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Imp (A):</label>
                                        <input type="text" className="form-control" readOnly={true} value={(moduloSeleccionado.imp === null)? "": moduloSeleccionado.imp} />
                                    </div>
                                </div> 
                                <div className="col-md-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Voc (V):</label>
                                        <input type="text" className="form-control" readOnly={true} value={(moduloSeleccionado.voc === null)? "": moduloSeleccionado.voc} />
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Isc (A):</label>
                                        <input type="text" className="form-control" readOnly={true} value={(moduloSeleccionado.isc === null)? "": moduloSeleccionado.isc} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-xs-6">
                                    <div className="form-group required">
                                        <label>Coef. T° (%/°C):</label>
                                        {(moduloSeleccionado.coefTempPotencia === null || moduloSeleccionado.coefTempPotencia === undefined)?
                                            <input type="number" step="any" className="form-control" {...register("coefTempPotencia", { required: true }) } />
                                            :
                                            <input type="text" className="form-control" 
                                                readOnly={true}
                                                value={moduloSeleccionado.coefTempPotencia} 
                                                {...register("coefTempPotencia", { required: true }) } />
                                        }
                                        {errors.coefTempPotencia?.type === 'required' && <p className="text-danger">Requerido.</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}

                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Marca inversor:</label>
                            <select className="form-control" {...register("marcaInversor", { required: true })} onChange={onMarcaInversorChange}>
                                <option value="">-- Seleccione Marca --</option>
                                {marcasInversores.map((m, i) => {
                                    return <option key={i} value={m}>{m}</option>
                                })}
                            </select>
                            {errors.marcaInversor?.type === 'required' && <p className="text-danger">Debe seleccionar la marca del inversor.</p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Inversor:</label>
                            <select className="form-control" {...register("inversor", { required: true })} onChange={onInversorChange}>
                                <option value="">-- Seleccione Inversor --</option>
                                {inversores.map((m, i) => {
                                    return <option key={i} value={m.id}>{m.modelo}</option>
                                })}
                            </select>
                            {errors.inversor?.type === 'required' && <p className="text-danger">Debe seleccionar el inversor.</p>}
                        </div>
                    </div>
                    {(muestraDatosInversor === true && inversorSeleccionado !== {}) && <>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-6 col-xs-6">
                                    <div className="form-group required">
                                        <label>Eficiencia (%):</label>
                                        <input type="text" className="form-control" readOnly={true} value={inversorSeleccionado.rendimientoMax} />
                                    </div>
                                </div>  
                                <div className="col-md-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Pot. Max. AC (kW):</label>
                                        <input type="text" className="form-control" readOnly={true} value={inversorSeleccionado.potenciaMaxSalidaAcKw} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Tipo de arreglo:</label>
                            <select className="form-control" {...register("tipoArreglo", { required: true })} onClick={onTipoArregloChange}>
                                <option value="">-- Seleccione Opción --</option>
                                <option value="hori">Horizontal</option>
                                <option value="tilt">Inclinado</option>
                                <option value="tilt_2elev">2 Inclinaciones por año</option>
                                <option value="hsat">HSAT</option>
                                <option value="htsat">HTSAT</option>
                                <option value="hsat_EO">HTSAT (EO)</option>
                                <option value="vsat">VSAT</option>
                                <option value="tsat">TSAT</option>
                                <option value="tracker_2axis">Seguimiento 2-ejes</option>
                            </select>
                            {errors.tipoArreglo?.type === 'required' && <p className="text-danger">Debe seleccionar el tipo de arreglo.</p>}
                        </div>
                    </div>         
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Angulo de inclinación:</label>
                            <select className="form-control" style={{pointerEvents: esArregloHorizontal === true? "none": "all"}}
                                readOnly={(esArregloHorizontal === true)? true: false} 
                                {...register("anguloInclinacion", { required: true })}>
                                
                                <option value="">-- Seleccione Opción --</option>
                                <option value="latitud">Igual a la latitud del lugar</option>

                                {Array.from(Array(91).keys()).map(i => {
                                    if (esArregloHorizontal && i === 0) return <option selected value={i} key={i}>{i}°</option>
                                    return <option value={i} key={i}>{i}°</option>
                                })}
                            </select>
                            {errors.anguloInclinacion?.type === 'required' && <p className="text-danger">Debe seleccionar el ángulo de inclinación.</p>}
                        </div>
                    </div> 
                    {(muestraAnguloInvierno === true) &&
                        <div className="col-md-4">
                            <div className="form-group required">
                                <label>Angulo de inclinación invierno:</label>
                                <select className="form-control" {...register("anguloInclinacionInvierno", { required: true })}>
                                    <option value="">-- Seleccione Opción --</option>
                                    <option value="latitud">Igual a la latitud del lugar</option>

                                    {Array.from(Array(91).keys()).map(i => {
                                        return <option value={i} key={i}>{i}°</option>
                                    })}
                                </select>
                                {errors.anguloInclinacionInvierno?.type === 'required' && <p className="text-danger">Debe seleccionar el ángulo de inclinación.</p>}
                            </div>
                        </div>
                    }
                    {(muestraInclinacionMaxHSAT === true) &&
                        <div className="col-md-4">
                            <div className="form-group required">
                                <label>Inclinación máxima HSAT:</label>
                                <input type="number" className="form-control" {...register("inclinacionMaxHSAT", { required: true, min: 0, max: 90 })} />
                                {errors.inclinacionMaxHSAT?.type === 'required' && <p className="text-danger">Debe ingresar máxima inclinación HSAT.</p>}
                                {(errors.inclinacionMaxHSAT?.type === 'min' || errors.inclinacionMaxHSAT?.type === 'max') && <p className="text-danger">El rango debe ser de 0° a 90°.</p>}
                            </div>
                        </div>
                    }
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Azimut:</label>
                            <select className="form-control" {...register("azimut", { required: true })}>
                                <option value="">-- Seleccione Opción --</option>
                                <option value="0">Norte (0°)</option>
                                <option value="90">Este (90°)</option>
                                <option value="180">Sur (180°)</option>
                                <option value="270">Oeste (270°)</option>
                            </select>
                            {errors.azimut?.type === 'required' && <p className="text-danger">Debe seleccionar el azimut.</p>}
                        </div>
                    </div>   
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Optimizar elevación y azimut</label>
                            <select className="form-control" {...register("optimizarElevacionAzimut", { required: true }) }>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Optimizar ángulo para la radiación global</label>
                            <select className="form-control" {...register("optimizarAnguloRadiacionGlobal", { required: true }) }>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                        </div>
                    </div> 
                    {/*********************************** */}
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Usar temperatura corregida</label>
                            <select className="form-control" {...register("tempCorregida", { required: true})}>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                            {errors.tempCorregida?.type === 'required' && <p className="text-danger">Debe seleccionar opción.</p>}
                        </div>
                    </div>               
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Cobertura:</label>
                            <select className="form-control" {...register("cobertura", { required: true })}>
                                <option value=""></option>
                                <option value="glass">Vidrio</option>
                                <option value="anti-reflectante">Anti-reflectante</option>
                            </select>
                            {errors.cobertura?.type === 'required' && <p className="text-danger">Debe seleccionar el tipo de cobertura.</p>}
                        </div>
                    </div>  
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Tipo de montaje:</label>
                            <select className="form-control" {...register("tipoMontaje", { required: true })}>
                                <option value="">-- Seleccione Opción --</option>
                                <option value="open_rack_cell_glassback">Abierto</option>
                                <option value="roof_mount_cell_glassback">Techo</option>
                            </select>
                            {errors.tipoMontaje?.type === 'required' && <p className="text-danger">Debe seleccionar el tipo de montaje.</p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Porcentaje pérdidas:</label>
                            <input className="form-control" type="number" defaultValue={14} {...register("porcPerdidas", { required: true, min: 1, max: 100 })} />
                            {errors.porcPerdidas?.type === 'required' && <p className="text-danger">Debe ingresar el porcentaje de pérdidas del sistema.</p>}
                            {(errors.porcPerdidas?.type === 'min' || errors.porcPerdidas?.type === 'max') && <p className="text-danger">El rango debe ser de 1% a 100%.</p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>DC/AC Ratio</label>
                            <input className="form-control"  type="number" step="any" {...register("dcAcRatio", { required: true, min: 0, max: 1 })} />
                            {errors.dcAcRatio?.type === 'required' && <p className="text-danger">Debe ingresar el ratio DC/AC.</p>}
                            {(errors.dcAcRatio?.type === 'min' || errors.dcAcRatio?.type === 'max') && <p className="text-danger">El rango debe ser de 0 a 1 (incluye decimales).</p>}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" onClick={showDatosContacto} {...register("deseaContacto")}/>
                            <label className="form-check-label">¿Desea ser contactado para una evaluación técnica?</label>
                        </div>
                    </div>

                    <DatosContacto show={muestraDatosContacto} register={register} errors={errors} />
                </div>
                <div className="text-right">
                    <br />
                    <button className="btn btn-primary solid blank">Enviar</button>	
                </div>
            </form>
        </>
    )
}

export default FormularioAvanzado