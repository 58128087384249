const DatosContacto = ({ show, register, errors } ) => {
    //const { formState: { errors } } = useForm()

    if (!show) return <></>
    
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
    const telefonoPattern = /^\+\d{2}\d{9}$/

    return (
        <>
            <span className="col-md-6">
                <div className="form-group required">
                    <label>Correo Electrónico:</label>
                    <input type="text" className="form-control" {...register("correoElectronico", { required: true, pattern: emailPattern } )} />
                    {errors.correoElectronico?.type === 'required' && <p className="text-danger">Este campo es obligatorio.</p>}
                    {errors.correoElectronico?.type === 'pattern' && <p className="text-danger">Formato de correo electrónico incorrecto.</p>}                     
                </div>  
            </span>

            <span className="col-md-6">
                <div className="form-group required">
                    <label>Teléfono:</label>
                    <input type="text" className="form-control" {...register("telefono", { required: true, pattern: telefonoPattern })} />
                    {errors.telefono?.type === 'required' && <p className="text-danger">Este campo es obligatorio.</p>}
                    {errors.telefono?.type === 'pattern' && <p className="text-danger">Formato de teléfono incorrecto (debe ser de la forma +56XXXXXXXXX).</p>}  
                </div>  
            </span>
        </>
    )
}

export default DatosContacto