import Header from "./components/Header"
import Footer from "./components/Footer"
import LoaderSpinner from "./components/LoaderSpinner"
import ResultadoCalculoBasico from "./components/ResultadoCalculoBasico"
import ResultadoCalculoAvanzado from "./components/ResultadoCalculoAvanzado"
import FormularioBasico from "./components/FormularioBasico"
import FormularioAvanzado from "./components/FormularioAvanzado"

import React,{ useState, useRef, useEffect } from "react"
import { Toast } from 'primereact/toast';
import ReCAPTCHA from "react-google-recaptcha"

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons

const CalculadoraPVApp = () => {
    const [loading, setLoading] = useState(false)
    const [muestraResultadosBasico, setMuestraResultadosBasico] = useState(false)
    const [muestraResultadosAvanzado, setMuestraResultadosAvanzado] = useState(false)
    const [dataResultados, setDataResultados] = useState({})
    const toast = useRef(null)
    const recaptchaRef = React.createRef()
   
    useEffect(() => {
    }, [])

    return (
        <>
            <Header />
            <Toast ref={toast} position="top-right" />

            <ResultadoCalculoBasico
                dataResultados={dataResultados}
                muestraResultados={muestraResultadosBasico} />

            <ResultadoCalculoAvanzado
                dataResultados={dataResultados}
                muestraResultados={muestraResultadosAvanzado} />

            <section id="main-container" className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Calculadora de Potencia Fotovoltaica</h3>
                            <p>Esta herramienta intentará a través de tu boleta de servicio eléctrico, obtener tus datos de cliente, dirección, compañía distribuidora y los valores de consumos promedios.</p>

                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="basico-tab" data-toggle="tab" href="#basico" role="tab" aria-controls="basico" aria-selected="true">Modelo Básico</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="avanzado-tab" data-toggle="tab" href="#avanzado" role="tab" aria-controls="avanzado" aria-selected="false">Modelo Avanzado</button>
                                </li>
                            </ul>

                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="basico" role="tabpanel" aria-labelledby="basico-tab">
                                    <FormularioBasico
                                        setLoading={setLoading}
                                        setMuestraResultados={setMuestraResultadosBasico} 
                                        setDataResultados={setDataResultados}
                                        toast={toast} 
                                        recaptchaRef={recaptchaRef} />
                                </div>
                                <div className="tab-pane fade" id="avanzado" role="tabpanel" aria-labelledby="avanzado-tab">
                                    <FormularioAvanzado
                                            setLoading={setLoading}
                                            setMuestraResultados={setMuestraResultadosAvanzado} 
                                            setDataResultados={setDataResultados}
                                            toast={toast} 
                                            recaptchaRef={recaptchaRef} />
                                </div>
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <LoaderSpinner show={loading} />
        </>
    )
}

export default CalculadoraPVApp