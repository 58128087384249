const Header = () => {
    return (
        <>
            <div className="body-inner">
                <div id="top-bar" className="top-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <ul className="top-info text-center text-md-left">
                                    <li><i className="fas fa-map-marker-alt"></i>
                                        <p className="info-text">Camino Las Bandurrias, Condominio Casas
                                            de Lo Arcaya, Parcela #13, Pirque, Chile</p></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-4 top-social text-center text-md-right">
                                <ul className="list-unstyled">
                                    <li>
                                        <a title="Facebook" href="https://www.facebook.com/vigorsolarchile" target="_blank" rel="noreferrer">
                                            <span className="social-icon"><i className="fab fa-facebook-f"></i></span>
                                        </a>
                                        <a title="Instagram" href="https://instagram.com/vigor.solar" target="_blank" rel="noreferrer">
                                            <span className="social-icon"><i className="fab fa-instagram"></i></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <header id="header" className="header-one">
                <div className="bg-white">
                    <div className="container">
                        <div className="logo-area">
                            <div className="row align-items-center">
                                <div className="logo-vigorsolar col-lg-3 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                                    <a className="d-block" href="index.php"><img src="/img/logo-vigorsolar2.png" alt="Vigorito" />
                                    </a>
                                </div>
                                <div className="col-lg-9 header-right">
                                    <ul className="top-info-box">
                                        <li>
                                            <div className="info-box">
                                                <div className="info-box-content">
                                                    <p className="info-box-title">Llámenos</p>
                                                    <p className="info-box-subtitle">
                                                        <a href="tel:+56968493787">+56 9 68493787</a> | <a href="tel:+56953725023">+56 9 53725023</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-box">
                                                <div className="info-box-content">
                                                    <p className="info-box-title">Escríbanos</p>
                                                    <p className="info-box-subtitle">
                                                        <a href="mailto:contacto@vigorsolar.cl">contacto@vigorsolar.cl</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                
                                        <li className="header-get-a-quote"><a className="btn btn-primary" href="https://www.vigorsolar.cl/contacto">Contacto</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="site-navigation">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div id="navbar-collapse" className="collapse navbar-collapse">
                                        <ul className="nav navbar-nav mr-auto">
                                            <li className="nav-item">
                                                <a className="nav-link" href="https://www.vigorsolar.cl">VigorSolar.cl</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>

                </div>
            </header>
        
            <div id="panelResultados" className="main-container">
                <div className="container">
                    <div className="gap-40"></div>	
                </div>
            </div>
        </>
    )
}

export default Header