import { fileToBase64 } from "../utils/Utils"

class CalculadoraPVService {

    async doCalculosBasico(data, captchaToken) {
        const file = data.boleta[0]
        const base64Str = await fileToBase64(file)
        const boleta = base64Str.split(",")[1]
        
        const res = await fetch(process.env.REACT_APP_CALCULADORA_PV_FUNC_URI + "/api/calculadora-pv", {
            method: 'POST',
            body: JSON.stringify({
                "deseaContacto": data.deseaContacto,
                "correoElectronico": data.correoElectronico,
                "telefono": data.telefono,
                "captchaToken": captchaToken,
                "boleta": boleta
            })
        });
        return await res.json();
    }

    async doCalculosAvanzado(data, moduloSeleccionado, inversorSeleccionado, captchaToken) {
        const file = data.boleta[0]
        const base64Str = await fileToBase64(file)
        const boleta = base64Str.split(",")[1]
        const bodyAvanzado = {
            "deseaContacto": data.deseaContacto,
            "correoElectronico": data.correoElectronico,
            "telefono": data.telefono,
            "captchaToken": captchaToken,
            "boleta": boleta,
            "moduloData": {
                "potenciaMax": moduloSeleccionado.potenciaMax,
                "rendimiento": moduloSeleccionado.rendimiento,
                "vmp": moduloSeleccionado.vmp,
                "isc": moduloSeleccionado.isc,
                "nroCelulas": moduloSeleccionado.nroCelulas,
                "pesoKg": moduloSeleccionado.pesoKg,
                "imp": moduloSeleccionado.imp,
                "voc": moduloSeleccionado.voc,
                "coefTempPotencia": data.coefTempPotencia,
                "coefTempVoc": moduloSeleccionado.coefTempVoc,
                "coefTempIsc": moduloSeleccionado.coefTempIsc
            },
            "inversorData": {
                "rendimientoMax": inversorSeleccionado.rendimientoMax
            },
            "instalacionData": {
                "tipoArreglo": data.tipoArreglo,
                "anguloInclinacion": data.anguloInclinacion,
                "anguloInclinacionInvierno": data.anguloInclinacionInvierno,
                "inclinacionMaxHSAT": data.inclinacionMaxHSAT,
                "azimut": data.azimut,
                "optimizarElevacionAzimut": data.optimizarElevacionAzimut,
                "optimizarAnguloRadiacionGlobal": data.optimizarAnguloRadiacionGlobal,
                "tempCorregida": data.tempCorregida,
                "cobertura": data.cobertura,
                "tipoMontaje": data.tipoMontaje,
                "porcPerdidas": data.porcPerdidas,
                "dcAcRatio": data.dcAcRatio
            },
        }

        const res = await fetch(process.env.REACT_APP_CALCULADORA_PV_FUNC_URI + "/api/calculadora-pv?avanzado=true", {
            method: 'POST',
            body: JSON.stringify(bodyAvanzado)
        });
        return await res.json();
    }

    async getMarcasModulos() {
        const res = await fetch("/data/marcasModulos.json")
        return await res.json();
    }

    async getModulos() {
        const res = await fetch("/data/modulos.json")
        return await res.json();
    }

    async getMarcasInversores() {
        const res = await fetch("/data/marcasInversores.json")
        return await res.json();
    }

    async getInversores() {
        const res = await fetch("/data/inversores.json")
        return await res.json();
    }
}

export default CalculadoraPVService