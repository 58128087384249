import React, { useState } from "react"

import DatosContacto from "./DatosContacto"
import CalculadoraPVService from "../services/CalculadoraPVService"
import { useForm } from "react-hook-form"
import { fileValidate } from "../utils/Utils"

const FormularioBasico = ({ setLoading, setMuestraResultados, setDataResultados, toast, recaptchaRef }) => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [muestraDatosContacto, setMuestraDatosContacto] = useState(false)
 
    const showDatosContacto = () => {
        setMuestraDatosContacto(!muestraDatosContacto)
    }

    const onSubmit = async (data) => {
        setLoading(true)

        // recaptcha token
        await recaptchaRef.current.executeAsync().then((captchaToken) => { 
            // servicio 
            new CalculadoraPVService().doCalculosBasico(data, captchaToken).then(data => {
                if (data.status === "ok") {
                    setMuestraResultados(true)
                    setDataResultados(data)
                    setLoading(false)
                } else {
                    // error en el servicio
                    toast.current.show({severity:'error', summary: 'Error', detail:'Error en el servicio', life: 3000})
                    setLoading(false)
                }
            })
            .catch(e => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Error en el servicio', life: 3000})
                setLoading(false)
            })
        })
    }

    return (
        <>
            <br />
            <small>* Los datos de generación solar para el modelo básico son obtenidos a través de la API de Energías Renovables del Ministerio de Energía (<a href="https://api.minenergia.cl/" target="_blank" rel="noreferrer">https://api.minenergia.cl</a>),
            asumiendo una instalación típica de 2kWp sobre superficie de techo inclinado con un ángulo igual a la latitud y con un coeficiente de temperatura del panel de -0.35 %/°C.</small>
            <br />
            <br />

            <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group required">
                            <label>Boleta de servicio:</label>
                            <input type="file" {...register("boleta", { required: true, validate: fileValidate })} />
                            {errors.boleta?.type === 'required' && <p className="text-danger">Debe seleccionar la boleta.</p>}
                            {errors.boleta?.type === 'validate' && <p className="text-danger">La boleta debe ser en formato PDF y no debe superar 1MB.</p>}
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" onClick={showDatosContacto} {...register("deseaContacto")}/>
                            <label className="form-check-label">¿Desea ser contactado para una evaluación técnica?</label>
                        </div>
                    </div>

                    <DatosContacto show={muestraDatosContacto} register={register} errors={errors} />
                </div>
                
                <div className="text-right">
                    <br />
                    <button className="btn btn-primary solid blank">Enviar</button>	
                </div>
            </form>
        </>
    )
}

export default FormularioBasico