const Footer = () => {
    return (
        <footer id="footer" className="footer bg-overlay">
            <div className="footer-main">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 col-md-6 footer-widget footer-about">
                            <h3 className="widget-title">Sobre Nosotros</h3><img src="img/texto-vigorsolar.png" alt="VigorSolar" width="200" />
                            <p>Juntos realizando un futuro energético más sustentable.</p>
                            
                            <div className="footer-social">
                                <ul>
                                    <li><a href="https://facebook.com/vigorsolarchile" aria-label="Facebook" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://instagram.com/vigor.solar" aria-label="Instagram" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
                            <h3 className="widget-title">Horario</h3>
                            <div className="working-hours">
                                Trabajamos de lunes a sábado, excepto domingos y días festivos. Contáctanos si tienes alguna pregunta a nuestros teléfonos o a través del formulario de contacto.
                                <br /><br /> Lunes - Viernes: <span className="text-right">08:30 - 18:30 </span>
                                <br /> Sábados: <span className="text-right">09:00 - 13:00</span>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
                            <h3 className="widget-title">Servicios</h3>
                            <ul className="list-arrow">
                                <li><a href="https://www.vigorsolar.cl/servicios">Energías Renovables</a></li>
                                <li><a href="https://www.vigorsolar.cl/servicios">Electricidad y automatización</a></li>
                                <li><a href="https://www.vigorsolar.cl/servicios">Planos</a></li>
                                <li><a href="https://www.vigorsolar.cl/servicios">IoT (Internet of Things)</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer