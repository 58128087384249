import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Loader } from "@googlemaps/js-api-loader"

const ResultadoCalculoBasico = (props) => {
    const [series, setSeries] = useState([])

    const chartOptions = {
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['mes 1', 'mes 2', 'mes 3', 'mes 4', 'mes 5', 'mes 6', 'mes 7', 'mes 8', 'mes 9', 'mes 10', 'mes 11', 'mes 12']
            //categories: [null, null, null, null, null, null, null, null, null, null, null, null]
        },
        yaxis: {
            title: {
                text: 'Generación Fotovoltaica (Kwh/mes)'
            },
            labels: {
                formatter: function (value) {
                    return value;
                }  
            }
        },
        fill: {
            colors: ['#ffb600'],
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function(val) {
                    return val +" kWh/mes"
                }
            }
        }
    }

    useEffect(() => {
        if (props.muestraResultados) {
            let dataSeries = props.dataResultados.data.generacionValores
            setSeries([{
                name: 'Generación Fotovoltaica',
                data: dataSeries
            }])

            new Loader({
                apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                version: "weekly",
                libraries: ["places"],
            }).load().then((google) => {
                const map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 14,
                    mapTypeId: 'roadmap',
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        //position: google.maps.ControlPosition.LEFT_BOTTOM,
                    }
                })

                const pinIcon = new google.maps.MarkerImage("/img/solcito_marker.png", null, null, null, null)

                new google.maps.Marker({
                    position: props.dataResultados.data.coordenadas,
                    map: map,
                    icon: pinIcon,
                    //title: planta.nombre
                })

                map.setCenter(props.dataResultados.data.coordenadas)
            });
        }
    }, [props])

    if (props.muestraResultados !== undefined && !props.muestraResultados) {
        return <></>
    }

    return (
        <div className="container">
            <div className="gap-40"></div>
            <div className="row text-center">
                <div className="col-12">  
                    <h3 className="section-sub-title">Información del Cliente</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                        <span className="ts-service-icon icon-round"> <i className="fa fa-user mr-0"></i>
                        </span>
                        <div className="ts-service-box-content">
                            <h4>Cliente</h4>
                            <p>{props.dataResultados.data.nombreCliente}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                        <span className="ts-service-icon icon-round"> <i className="fas fa-bolt mr-0"></i>
                        </span>
                        <div className="ts-service-box-content">
                            <h4>Consumo Promedio Mensual</h4>
                            <p>{props.dataResultados.data.promedioConsumo} kWh/mes</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                        <span className="ts-service-icon icon-round"> <i className="fa fa-globe-africa mr-0"></i>
                        </span>
                        <div className="ts-service-box-content">
                            <h4>Emisiones de Co2 promedio</h4>
                            <p>{props.dataResultados.data.co2GeneradoPromedio.toFixed(2)} Kg/mes</p>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="row text-center">
                <div className="col-12">
                    
                    <h3 className="section-sub-title">Generación Fotovoltaica</h3>
                </div>
            </div>
            <div className="row">	
                <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                        <span className="ts-service-icon icon-round"> <i className="fa fa-solar-panel mr-0"></i>
                        </span>
                        <div className="ts-service-box-content">
                            <h4>Total Anual</h4>
                            <p>{props.dataResultados.data.generacionTotalAnual.toFixed(2)} Kw/año</p>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                        <span className="ts-service-icon icon-round"> <i className="fa fa-solar-panel mr-0"></i>
                        </span>
                        <div className="ts-service-box-content">
                            <h4>Promedio Mensual</h4>
                            <p>{props.dataResultados.data.generacionPromedioMensual.toFixed(2)} Kw/mes</p>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-4">
                    <div className="ts-service-box-bg text-center h-100">
                        <span className="ts-service-icon icon-round"> <i className="fa fa-solar-panel mr-0"></i>
                        </span>
                        <div className="ts-service-box-content">
                            <h4>Promedio Diario</h4>
                            <p>{props.dataResultados.data.generacionPromedioDiario.toFixed(2)} Kw/dia</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gap-60"></div>
            
            <div className="row">
                <div className="col-md-12">
                    <Chart
                        options={chartOptions}
                        series={series}
                        type="bar"/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div id="map" style={{width: '100%', minHeight: '500px'}}></div>
                </div>
            </div>
            
            <div className="gap-60"></div>
            <h4 className="text-center hr-resultados">La instalación de 2Kwp de paneles fotovoltaicos cubriría un {((props.dataResultados.data.generacionPromedioMensual*100)/props.dataResultados.data.promedioConsumo).toFixed(2)}% de su demanda energética mensual.</h4>
            
            <hr />
        </div>
    )
}

export default ResultadoCalculoBasico